 

.valid-regex {
  color: hsl(120, 70%, 73%);
  font-size: 20px;
}

.invalid-regex {
  color: #999;
  font-size: 20px;
}

.loader-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
}

.svg-loader {
  width: 200px;
  margin-top: 30px;
}

.validating-text {
  color: rgb(199, 199, 199);
  font-size: 1.2rem;
  align-self: center;
  margin: 2px;
}

.validation-error {
  color: red;
  font-size: 22px;
  margin: 0px;
}

