.nug-button { 
  background-color: black;
  padding: 7px 20px 10px;
  /* margin-left: 10px; */
  border-radius: 10px;
  line-height: 1.2rem;
  font-size: 1.2rem;
  cursor: pointer;
}

.nug-button:nth-of-type(2){
  margin: 0px 10px;
}

.nug-button:active {
  background-color: #eee;
  color: #333;
}
.nugget-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nugget-container {
  width: 300px;
  margin: 0px auto;
}

@media(max-width: 600px){
  .nug-button {
    font-size: 1rem;
    line-height: 1rem;
    padding: 10px 20px 10px;
    margin-left: 0px;
  }
  .nugget-container {
    width: 250px;
  }
}