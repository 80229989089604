

.question {
  padding: 15px 25px;
  color: rgb(27, 27, 27);
  font-size: 1.6em;
  line-height: 1.2;
  align-self: flex-start;
  background-color: #2cc1f8;
  border-radius: 14px;
  font-weight: 400;
  max-width: 80%;
  margin-bottom: 10px;
  box-shadow: 5px 5px 4px;
}

.answer {
  margin-top: 3px;
  padding: 30px 30px;
  color: rgb(202, 202, 202);
  font-size: 1.6em;
  line-height: 1.4;
  align-self: flex-start;
  background-color:#034963;
  border-radius: 20px;
  font-weight: 400;
  margin-left: 30px;
  max-width: 90%;
  box-shadow: -3px -3px 3px rgb(26, 26, 26) inset;
  /* text-indent: 10px; */
}