.hero-container {
  margin-top: 40px;
  width: 100%;
  /* height: 600px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.profile-pic {
  height: 300px;
  z-index: 50;
}

.profile-pic-wrapper {
  overflow: hidden;
  background-color: #2cc1f8;
  border-radius: 50%;
  padding-top: 10px;
  display: flex;
  width: 300px;
  object-fit: cover;
}

.my-name {
  font-size: 2.2rem;
  font-weight: 300;
  margin-top: 8px;
  color: #b9e7f8;
}