input[type=range] {
  height: 38px;
  -webkit-appearance: none;
  margin: 5px 0;
  background-color: #282c34;
  width: 50%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  box-shadow: 1px 1px 1px #000000;
  background: steelblue;
  border-radius: 5px;
  border: 1px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 20px;
  border-radius: 5px;
  background: #009bf5;
  cursor: grab;
  -webkit-appearance: none;
  margin-top: -11px;
}
input[type=range]::-webkit-slider-thumb:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: steelblue;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000;
  background: steelblue;
  border-radius: 5px;
  border: 1px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 20px;
  border-radius: 5px;
  background: #009bf5;
  cursor: grab;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: grab;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: steelblue;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
input[type=range]::-ms-fill-upper {
  background: steelblue;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 20px;
  border-radius: 5px;
  background: #009bf5;
  cursor: grab;
}

input[type=range]::-ms-thumb:active {
  cursor:grabbing;
}
input[type=range]:focus::-ms-fill-lower {
  background: steelblue;
}
input[type=range]:focus::-ms-fill-upper {
  background: steelblue;
}

.bar-slide-container {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
}

.slide-text-container {
  display:flex;
  justify-content:space-between;
  text-align:right;
  color:#c2e9ff;
}

.slide-text-number {
  width: 60px;
  margin-left: 10px;
}

.slide-color-box {
  margin-top: 30px; 
  width: 100%; 
  height: 300px;
  border-radius: 20px; 
}